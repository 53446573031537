
import React from 'react'
import NoComments from './NoComments';

import styles from './PostBoard.module.css';
import PostBody from './PostBody';

const PostBoard = ({posts}) => {
  return (
    <div className={styles.wrapper}>
    
    <h1 className={styles.title}>Comments</h1>
    {posts.length > 0 ? 
        posts.map(post =>(
            <PostBody key={post.id} post={post} />
        )) 
    :
        <NoComments />
    }
    </div>
  )
}

export default PostBoard