import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Bowser from 'bowser';
import { motion } from 'framer-motion';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { MyCourseContext } from '../../shared/context/myCourse-context'



import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';


import styles from './LoginForm.module.css';

const LoginForm = () => {
 

  const pageVariants = {
    initial: {
      opacity: 0,
      x: 200,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: -200,
    },
  };

  const linkStyle = {
    textDecoration: "none",
    color: 'white',
    fontSize: '1.6rem'
  };

  const { REACT_APP_API_URL } = process.env;

  const auth = useContext(AuthContext);
  const { createMyCourses } = useContext(MyCourseContext);

  const navigate = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const agentStats = Bowser.parse(window.navigator.userAgent);

  const [errors, setErrors] = useState();
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);


  const submitHandler = async event => {
    event.preventDefault();

    const email = event.target.email.value || null;
    const password = event.target.password.value || null;

    resetErrors();

    if (email === null) {
      setErrors({ email: { type: 'required' } });
      setEmailError(true);
    } else if (password === null) {
      emailError && setEmailError(() => false);
      setErrors({ password: { type: 'required' } });
      setPassError(true);
    } else {
      const browserName = agentStats.browser.name;
      const browserVerion = agentStats.browser.version;
      const osName = agentStats.os.name;
      const osVersion = agentStats.os.version;
      const osVersionName = agentStats.os.versionName;
      const platform = agentStats.platform.type;

      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/auth/teacher`,
          'POST',
          JSON.stringify({
            email: email,
            password: password,
            browserName: browserName,
            browserVerion: browserVerion,
            osName: osName,
            osVersion: osVersion,
            osVersionName: osVersionName,
            platform: platform
          }),
          {
            'Content-Type': 'application/json',
          }
        );

console.log(response);
          auth.login(
            response.userId,
            response.email,
            response.firstName,
            response.lastName,
            response.token
          );
          createMyCourses(response.myCourses);
          navigate('/');
        

        
      } catch (err) {
        console.log(err);
      }
    }
  };

  const navLoginHandler = () => {
    navigate(`/enrol`);
  };

  const resetErrors = () => {
    setErrors();
    setEmailError(false);
    setPassError(false);
  };

 

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <motion.div
        key={'loginForm'}
        className={styles.formWrapper}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <h1 className={styles.formTitle}>{'Login'}</h1>
        {isLoading && (
          <div className="center">
            <LoadingSpinner classNameWrapper={styles.loadingWrapper}/>
          </div>
        )}

        {!isLoading && (
          <form className={styles.form} onSubmit={submitHandler}>
            
            <div className={styles.inputWrapper}>
              <label>
                {`email`}:
                <span
                  className={
                    emailError ? styles.errorInputLabel : styles.noError
                  }
                >
                  {errors &&
                    errors.email &&
                    errors.email.type === 'required' &&
                    `email is required!`}
                </span>
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder={`email`}
                inputClassName={`${styles.input} ${
                  emailError ? styles.errorInput : styles.noError
                }`}
              />
              <label>
                {`password`}:
                <span
                  className={
                    passError ? styles.errorInputLabel : styles.noError
                  }
                >
                  {errors &&
                    errors.password &&
                    errors.password.type === 'required' &&
                    `password is required!`}
                  {errors &&
                    errors.password &&
                    errors.password.type === 'min char' &&
                    `password must contain at least 5 characters`}
                </span>
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder={`password`}
                inputClassName={`${styles.input} ${
                  passError ? styles.errorInput : styles.noError
                }`}
              />
            </div>
            <div className={styles.actions}>
              <Button
                type="submit"
                className={`button-primary ${styles.enterBtn}`}
                title={`enter`}
              />
              <Button
                type="button"
                className={`button-white ${styles.registerBtn}`}
                title={`enrol`}
                onClick={navLoginHandler}
              />
            </div>
            <Link style={linkStyle} to={`/forgot`}>{'forgot password?'}</Link>
          </form>
        )}

      
       
      </motion.div>
    </>
  );
};

export default LoginForm;
