import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

import styles from './Ring.module.css';

const Ring = ({completed, last}) => {

    return (
        <div id={styles.ringWrapper}>
            <div id={styles.ring}>
                {completed ? <FontAwesomeIcon icon={faCheckCircle}/> : ''}
            </div>
           {!last && <div id={styles.line}></div>}
        </div>
    )
}

export default Ring
