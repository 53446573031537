import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { MyCourseContext } from '../context/myCourse-context';

export const useMyCurrentCourse = () => {
  const [myCourse, setMyCourse] = useState(null);
  const { myCourses } = useContext(MyCourseContext)

  let { cid } = useParams();

  useEffect(() => {
    myCourses && setMyCourse(myCourses.find(c => c.courseId === cid));
  }, [myCourses, cid]);
  

  return { myCourse };
};
