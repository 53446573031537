import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import TopLinks from './TopLinks';
import MobileNav from './MobileNav';
import './MainHeader.css';

const MainHeader = props => {

  const { REACT_APP_API_URL } = process.env;
  const { sendRequest } = useHttpClient();
  const { token } = useContext(AuthContext);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/course`,
          'GET'

        );
        console.log(responseData);
        setCourses(responseData.courses);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCourse();
  }, []);

  return (
    <Fragment>
   
      <header className="header-container">
     
        <div className="header-container__shell">
          <div className="header-conatiner__top">
           
              <div className="main-brand">
                <Link to='/'>
                  <img src="https://meerkat.software/hfm/img/logo.svg" alt="logo" />
                </Link>
              </div>
              <p className="header-container__info header-text">
                <span className="header-container__title">
                  <FontAwesomeIcon icon="phone" />
                </span>
                &nbsp; 0203 849 4989 &nbsp; 
                <span className="header-container__title header-email">
                  | &nbsp;
                  <FontAwesomeIcon icon="envelope" />
                  &nbsp; info@hellofutureminds.co.uk
                </span>
              </p>
            </div>
          
          <div className="header-conatiner__nav_top">
            <div className="wrapper right">
            <h1 className='header-brand-text'>Hello Future Minds</h1>
              <nav className="main-navigation__header-nav">
                <TopLinks courses={courses}/>
                <MobileNav courses={courses}/>
              </nav>
            </div>
          </div>
        </div>
       

       
      </header>
    </Fragment>
  );
};

export default MainHeader;
