import { useState, useCallback, useEffect } from 'react';

export const useMyCourses = () => {
  const [myCourses, setMyCourses] = useState(null);
  
  const createMyCourses = useCallback((myCourses) => {
    setMyCourses(()=>myCourses);
  
    console.log('setting my courses Context');
    
    localStorage.setItem(
        'myCourses',
        JSON.stringify({
          myCourses: myCourses
        })
      );
    },
    []
  );

  const updateIsComplete = useCallback((lessonComplete) => {
    const storedData = JSON.parse(localStorage.getItem('myCourses'));
    const myCourse = storedData.myCourses.find(myCourse => myCourse.id === lessonComplete.myCourseId);
    
    if (myCourse) {
      const myLesson = myCourse.myLessons.find(lesson => lesson.id === lessonComplete.id);
      myLesson.isComplete = lessonComplete.isComplete;
      console.log(storedData.myCourses);
      setMyCourses(storedData.myCourses);
    }
    
  }, [])

  const clearMyCourses = useCallback(() => {
    setMyCourses(null);
    localStorage.removeItem('myCourses');
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('myCourses'));
    storedData && createMyCourses(
    storedData.myCourses,
    
    );
    
  }, [createMyCourses]);

  return { myCourses, createMyCourses, updateIsComplete, clearMyCourses };
};
