import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CardCharge from '../../../shared/components/PaymentGateway/stripe/components/CardCharge';

import { AuthContext } from '../../../shared/context/auth-context';
import { useIsPurchased } from '../../../shared/hooks/isPurchased-hook';

import Button from '../../../shared/components/UIElements/Buttons/Button';
import styles from './CardPayments.module.css';

const CardPayments = ({ courseData, success }) => {
  const { isPurchased } = useIsPurchased(courseData.id);
  const { userId, isLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  const enrolHandler = () => {
    navigate(`/enrol`);
  };

  const authHandler = () => {
    navigate(`/auth`);
  };

  const transactionCompleteHandler = () => {
    console.log('purchased');
    success();
    
  };

  return (
    <div className={styles.paymentConatiner}>
      {!isPurchased ? (
        <h1 className={styles.price}>{`£${(courseData.price / 100).toFixed(
          2
        )}`}</h1>
      ) : (
        <h1 className={styles.price}>Purchased</h1>
      )}
      {!isPurchased && <p className={styles.priceSub}>Price in GBP </p>}

      {!isLoggedIn ? (
        <div className={styles.action}>
          <Button
            className={`button-warning button-100 ${styles.actionButton}`}
            title="Enrol"
            onClick={enrolHandler}
          />
          <Button
            className={`button-100 ${styles.actionButton}`}
            title="Login"
            onClick={authHandler}
          />
          <div className={styles.actionNotice}>
            <p>You must be enrolled as a student to purchase a course.</p>
          </div>
        </div>
      ) : null}

      {isLoggedIn && !isPurchased ? (
        <CardCharge
          amount={courseData.price}
          desc={courseData.title}
          userId={userId}
          courseId={courseData.id}
          success={transactionCompleteHandler}
        />
      ) : null}

      {isLoggedIn && isPurchased ? (
        <div className={styles.action}>
          <div className={styles.actionNotice}>
            <p>You have purchased this course.</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CardPayments;
