import React from 'react'

import VideoPlayer from '../../../shared/components/Video/VideoPlayer';
import styles from './LessonVideo.module.css';

const LessonVideo = ({url}) => {


    const progress = data => {
        console.log(data);
    }


  return (
    <div className={styles.wrapper}>
        
            <VideoPlayer url={url} onProgress={progress}/>
        
    </div>
  )
}

export default LessonVideo