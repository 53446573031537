import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faFilePdf,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect ,useState} from 'react';
import parse from 'html-react-parser';
import FeaturedImage from '../../shared/components/UIElements/FeaturedImage';

import styles from './CourseHeader.module.css';
import CardPayments from './paymentGateway/CardPayments';


const videoCount = course => {
  let result = 0;
  if (course) {
    if (course.lessons) {
      for (let x = 0; x < course.lessons.length; x++) {
        course.lessons[x].videoUrl && result ++
      }
    }
  }
  return result;
}

const workbookCount = course => {
  let result = 0;
  if (course) {
    if (course.lessons) {
      for (let x = 0; x < course.lessons.length; x++) {
        course.lessons[x].downloadableUrl && result ++
      }
    }
  }
  return result;
}

const totalDuration = course => {
  let result = 0;
  if (course) {
    if (course.lessons) {
      for (let x = 0; x < course.lessons.length; x++) {
        if (course.lessons[x].duration) {
          result = result + parseInt(course.lessons[x].duration)
        }
      }
    }
  }
  return result;
}



const CourseHeader = ({ course, update }) => {
console.log(`Video Count: ${videoCount(course)}`);

const [videos, setVideos] = useState(0);
const [workbooks, setWorkbook] = useState(0);
const [duration, setDuration] = useState(0)


  useEffect(() => {
    if (course) {
      const vc = videoCount(course);
      const wc = workbookCount(course);
      const dur = totalDuration(course);
      setVideos(vc);
      setWorkbook(wc);
      setDuration(dur);
    }
  
    
  }, [course])
  
  const successHandler = () => {
   update()
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.container}>
        <div className={styles.col}>
          <h1 className={styles.mainTitle}>{course.title}</h1>
          <div className={styles.featureContainer}>
            <p className={styles.features}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faGraduationCap} />
              </span>
              {course.lessons.length}
              <span className={styles.lessonNumber}>Lessons</span>
            </p>
            <span className={styles.seperator}>|</span>
            {workbooks > 0 && <p className={styles.features}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className={styles.amount}>
              {workbooks}
              </span>
              Workbooks
            </p>}
            {workbooks > 0 &&  <span className={styles.seperator}>|</span>}
            {videos > 0 && <p className={styles.features}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className={styles.amount}>
              {videos}
              </span>
              Videos
            </p>}
            {videos > 0 &&  <span className={styles.seperator}>|</span>}
            <p className={styles.features}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faClock} />
              </span>
              <span className={styles.amount}>
              {(Math.floor(duration / 60) + ':' + duration % 60)}
              </span>
               Hours
            </p>
          </div>
          <div className={styles.descContainer}>
            {course && course.description && (
              <p className={styles.desc}>{parse(course.description)}</p>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.courseDetails}>
            <FeaturedImage src={course.featureImage} />

            <CardPayments courseData={course} success={successHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseHeader;
