import React from 'react';
import Button from '../Buttons/Button';

import Modal from './Modal';

import styles from './ErrorModal.module.css';


const ErrorModal = ({onClear, error }) => {
  return (
    <Modal
      modalClassName={styles.errorModal}
      onCancel={onClear}
      show={!!error}
      btnClassName={styles.modalBtn}
      footer={<Button className='modal-button' onClick={onClear} title="Ok"/>}
    >
    <h1 className={styles.title}>Error!</h1>
      <p className={styles.text}>{error}</p>
    </Modal>
  );
};

export default ErrorModal;
