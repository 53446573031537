import React, { useContext, Fragment, useState, useEffect } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import UpdateTeacherPassword from './UpdateTeacherPassword';
import UpdateTeacher from './UpdateTeacher';

import './MyAccountSettings.css';
import LoadingSpinnerCenter from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';



const MyAccountSettings = () => {
  const { REACT_APP_API_URL } = process.env;
  const { userId, isLoggedIn, token  } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [userData, setUserData] = useState();

  useEffect(() => {

    console.log(userId);
    const getUser = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/teacher/${userId}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }
        );
        console.log(response);
        if (response.teacher) {
          console.log(response.teacher);
    
            setUserData(response.teacher);
          
        }
      } catch (err) {
        //console.log(err);
      }
    };
    isLoggedIn && userId && getUser();
  }, [isLoggedIn, userId]);

  return (
    <Fragment>
    {isLoading && <div className='ma-loading-container'><LoadingSpinnerCenter/></div>}
      <section id="my-account-settings">
        <div className="my-account-settings-content">
          <h1 className="my-account-settings-title">Personal Details</h1>
          <div className="my-account-settings-inner-content">
            <div className="personal-settings-container">
            {userData && <UpdateTeacher userData={userData} />}
            </div>
            <div className="personal-settings-container">
            {userData && <UpdateTeacherPassword userData={userData} />}
            </div>
          </div>
        </div>
      </section>
      <ErrorModal error={error} onClear={clearError} />

    </Fragment>
  );
};

export default MyAccountSettings;
