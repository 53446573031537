import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { useHttpClient } from '../../../../hooks/http-hook';
import ErrorModal from '../../../UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../../UIElements/Loading/LoadingSpinnerDark';

import styles from './CardCharge.module.css';
import Button from '../../../UIElements/Buttons/Button';

const CheckoutForm = props => {

  const { REACT_APP_API_URL } = process.env;
  const stripe = useStripe();
  const elements = useElements();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setProcessing(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {

      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/stripe/charge`,
          'POST',
          JSON.stringify({
            paymentMethod: paymentMethod,
            amount: props.amount,
            description: props.desc,
            userId: props.userId,
            courseId: props.courseId
          }),
          {
            'Content-Type': 'application/json',
          }
        );
        console.log(responseData);

        if (responseData.confimred) {
            props.success();
        }



      } catch (err) {
        console.log(err);
      }
    }
    setProcessing(false);
  };

  return (
    <div className={styles.wrapper}>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner message='Processing Order...'/>
        </div>
      )}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.input}>
          <CardElement />
        </div>
        <div className={styles.action}>
          {!processing ? (
            <Button
              className={`button-warning ${styles.button}`}
              type="submit"
              disabled={!stripe}
              title="BUY NOW"
            />
          ) : (
            <Button
              className={`button-disabled ${styles.button}`}
              disabled={true}
              title="Processing"
            />
          )}
        </div>
      </form>
    </div>
  );
};

const stripePromise = loadStripe(
  'pk_test_51GzsR8ByjOz83QsVcfSmfzncBt1bgjgBdhcg9OcEYgIiZQcVxF202eZUnMP1u07PxfDaS0ywaxwiLtjj9aRyvtUi00igMjY6np'
);

const CardCharge = props => (
  <Elements stripe={stripePromise}>
    <CheckoutForm amount={props.amount} success={props.success} desc={props.desc} userId={props.userId} courseId={props.courseId}/>
  </Elements>
);

export default CardCharge;
