import { useState, useCallback, useContext, useEffect } from 'react';

import { useHttpClient } from './http-hook';
import { AuthContext } from '../context/auth-context';

export const useCourses = () => {
  const [courses, setCourses] = useState([]);

  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  const getCourses = async () => {

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/course/`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response);
      if(response) {
        if (response.courses) {
          setCourses(response.courses);
        }
      }
        
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {
    courses.length === 0 &&  getCourses();
  }, []);
  
  
  
  const createCourses = useCallback((courses) => {
    setCourses(courses);
    console.log('setting my courses Context');
    },
    []
  );

  const clearCourses = useCallback(() => {
    setCourses([]);
  }, []);

  return { courses, createCourses, clearCourses };
};
