import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import Button from '../../../shared/components/UIElements/Buttons/ButtonWithChildren';
import ErrorModal from '../../../shared/components/UIElements/Modal/ErrorModal';
import styles from './LessonComplete.module.css';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const LessonComplete = ({myLesson, myCourseId, lesson, complete}) => {

    const [isComplete, setIsComplete] = useState(false);
    const auth = useContext(AuthContext);
    const { error, sendRequest, clearError } = useHttpClient();
    const { REACT_APP_API_URL } = process.env;

    const completeLesson = () => {
      console.log('click');

      myLesson ?  updateMyLesson() : createAndCompleteMyLesson()
    }

    useEffect(() => {
      console.log(myLesson);
        myLesson && setIsComplete(myLesson.complete);
        !myLesson && setIsComplete(false);
    }, [myLesson])


    const updateMyLesson = async () => {
        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/mylesson/complete/${myLesson.id}`,
              'PATCH',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
      
            console.log(response);
            if(response) {
                if (response.isSuccess) {
                    
                    setIsComplete(true)
                    console.log(myLesson);
                    complete();
                }
            }
              
          } catch (err) {
            console.log(err);
          }
    }

    const createAndCompleteMyLesson = async () => {
      try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/mylesson/create/complete/${lesson.id}`,
            'POST',
            JSON.stringify({
              lessonNumber:lesson.lessonNumber,
              teacherId: auth.userId,
              myCourseId
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
    
          console.log(response);
          if(response) {
              if (response.isSuccess) {
                  
                  
                  setIsComplete(true)
                  console.log(myLesson);
                  complete();
              }
          }
            
        } catch (err) {
          console.log(err);
        }
  }
    

  return (
      <div className={styles.wrapper}>
        <ErrorModal error={error} onClear={clearError}/>
       {!isComplete ?
        <Button className={styles.button} title='Mark as Complete' onClick={completeLesson}>
        <span><FontAwesomeIcon icon={faTimes} /></span>
        <p>Mark as Complete</p>
        </Button>
        :
        <div className={styles.isCompleteWrapper} >
            <FontAwesomeIcon icon={faCheck} />
            <p>Lesson Complete</p>
        </div>
        }
      </div>
    
  )
}

export default LessonComplete