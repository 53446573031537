import React, {useState} from 'react'

import styles from './LessonDetails.module.css';

import LessonText from './LessonText';
import LessonVideo from './LessonVideo';
import LessonPDF from './LessonPDF';
import LessonComplete from './LessonComplete';
import Discussion from '../../../shared/components/Discussion/Discussion';

const LessonDetails = ({ lesson, myLesson, myCourseId, complete}) => {

console.log(lesson);
console.log(myLesson);

  return (
    <div className={styles.wrapper}>
        <div className={styles.header}>
            <h1 className={styles.lessonTitle}>{lesson.title}</h1>
            <LessonComplete lesson={lesson} myLesson={myLesson} myCourseId={myCourseId} complete={complete}/>
        </div>
        <div className={styles.body}>
        {lesson.text && <LessonText text={lesson.text}/>}
        {lesson.videoUrl && <LessonVideo url={lesson.videoUrl}/>}
        {lesson.downloadableUrl && <LessonPDF url={lesson.downloadableUrl}/>}
        


        </div>
        {lesson && <Discussion lessonId={lesson.id}/>}
    </div>
  )
}

export default LessonDetails