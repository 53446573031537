import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faClock,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faPhone,
  faEnvelope,
  faFileContract,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import Auth from './auth/pages/Auth';
import Dashboard from './dashboard/pages/dashboard';
import Settings from './UserAccount/pages/UserAccount';
import Courses from './course/pages/Courses';
import Course from './course/pages/Course';

import Header from './shared/components/Header/MainHeader';
import Footer from './shared/components/Footer/MainFooter';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import { useMyCourses } from './shared/hooks/myCourse-hook';
import { MyCourseContext } from './shared/context/myCourse-context';
import { CourseContext } from './shared/context/courses-context';
import { useCourses } from './shared/hooks/course-hook';

import './App.css';

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faClock,
  faCalendarAlt,
  faSearch,
  faPhone,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faFileContract,
  faExclamationTriangle
);

function App() {
  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  const { myCourses, createMyCourses, updateIsComplete, clearMyCourses } = useMyCourses();
  const { courses, createCourses, clearCourses } = useCourses();

  let routes;

  token
    ? (routes = (
        <>
          <Header />
          <Routes>
            <Route path="/" exact element={<Courses />} />
            <Route path="/courses" exact element={<Courses />} />
            <Route path="/course/:cid" exact element={<Course />} />
            <Route path="/account-settings" exact element={<Settings />} />
            <Route path="*" element={<Courses />} />
          </Routes>
        </>
      ))
    : (routes = (
      <>
      <Header />
        <Routes>
          <Route path="/auth" exact element={<Auth />} />
          <Route path="*" element={<Auth />} />
        </Routes>
        </>
      ));

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl: userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}
    >
      <MyCourseContext.Provider
        value={{
          myCourses: myCourses,
          createMyCourses: createMyCourses,
          updateIsComplete: updateIsComplete,
          clearMyCourses: clearMyCourses,
        }}
      >
        <CourseContext.Provider
          value={{
            courses: courses,
            createCourses: createCourses,
            clearCourses: clearCourses,
          }}
        >
          <Router>
            <main>
              <Suspense
                fallback={
                  <div className="center">
                    <LoadingSpinner />
                  </div>
                }
              >
                {routes}
              </Suspense>
            </main>
            <Footer />
          </Router>
        </CourseContext.Provider>
      </MyCourseContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
