import React, { useEffect, useState, useContext } from 'react';
import { motion } from 'framer-motion';

import { useParams } from 'react-router-dom';
import Sidebar from '../../shared/components/UIElements/Sidebar';

import styles from './Course.module.css';
import CourseHeader from '../components/CourseHeader';
import { CourseContext } from '../../shared/context/courses-context';
import { MyCourseContext } from '../../shared/context/myCourse-context';
import { CourseIndex } from '../components/CourseIndex';
import { useIsPurchased } from '../../shared/hooks/isPurchased-hook';
import { useUpdateMyCourses } from '../../shared/hooks/updateMyCourses-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import GaugePanel from '../components/GaugePanel';
import CourseIndexNav from '../components/sidebarIndex/CourseIndexNav';
import LessonDetails from '../components/lessons/LessonDetails';

const Course = () => {

  let { cid } = useParams();
  const [courseData, setCourseData] = useState();
  const [myCourseData, setMyCourseData] = useState();
  const { courses } = useContext(CourseContext);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { REACT_APP_API_URL } = process.env;
  const { getMyCourses } = useUpdateMyCourses();
  const { myCourses, createMyCourses } = useContext(MyCourseContext);
  const { isPurchased } = useIsPurchased(cid);
  const [lessonNumber, setLessonNumber] = useState(0);
  const [lesson, setLesson] = useState();
  const [myLesson, setMyLesson] = useState();
  const [update, setUpdate] = useState(false);

  console.log(cid);
  console.log(courses);

  const selectedLessonHandler = lessonNum => {
    setLessonNumber(lessonNum);
    console.log(myCourses);
    const selectedLesson = courseData.lessons.find(lesson => lesson.lessonNumber === lessonNum);
    const myCourse = myCourses.find(course => course.courseId === cid);
    const selectedMyLesson = myCourse.myLessons.find(lesson => lesson.lessonNumber === lessonNum);
    console.log(selectedLesson);
    setLesson(selectedLesson);
    setMyLesson(selectedMyLesson);
  }

  useEffect(() => {
    fetchMyCourses();
  },  [update]);

  useEffect(() => {
    console.log(myCourses);
    myCourses && setMyCourseData(myCourses.find(myCourse => myCourse.courseId === cid))
  }, [myCourses])

  const fetchMyCourses = async () => {

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/mycourse/get/${auth.userId}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response);
      if(response) {
        if (response) {
          createMyCourses(response);
        }
      }
        
    } catch (err) {
      console.log(err);
    }
  }
  
  
  useEffect(() => {
    if (courses) {
      const selectedCourse = courses.find( ({ id }) => id === cid );
      selectedCourse && setCourseData(selectedCourse);
      console.log('COURSE');
      console.log(selectedCourse);
    }
  }, [courses, cid]);

  return (
    <div className={styles.dashboardWrapper}>
      <Sidebar>
        <GaugePanel />
        {isPurchased && courseData && myCourseData && <CourseIndexNav myCourseData={myCourseData} courseData={courseData} selectedLession={selectedLessonHandler} update={()=>setUpdate(state => !state)} />} 
      </Sidebar>
      <motion.div
        key="course"
        className={styles.contentWrapper}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5 }}
      >
        {courseData && lessonNumber === 0 && <CourseHeader course={courseData} isPurchased={isPurchased} update={()=>setUpdate(state => !state)} />}
        {courseData && lessonNumber === 0 && <CourseIndex courseData={courseData}/>}
        {myCourseData && lesson && lessonNumber !== 0 && <LessonDetails lesson={lesson} myLesson={myLesson} myCourseId={myCourseData.id} complete={()=>setUpdate(state => !state)} />}
        {/* {courseData && !lesson && lessonNumber !== 0 && <LessonDetails />} */}
        
      </motion.div>
    </div>
  );
};

export default Course;
