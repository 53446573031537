import React, { useContext, Fragment, useState, useEffect } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';


import './MyAccountSettings.css';
import LoadingSpinnerCenter from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';



const MyAccountAddress = () => {
  const { REACT_APP_API_URL } = process.env;
  const { userId, isLoggedIn, token  } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [userData, setUserData] = useState();

  
 


 

  return (
    <Fragment>
    {isLoading && <div className='ma-loading-container'><LoadingSpinnerCenter/></div>}
      <section id="my-account-settings">
        <div className="my-account-settings-content">
          <h1 className="my-account-settings-title">School & Address</h1>
          <div className="my-account-settings-inner-content">
           
          </div>
        </div>
      </section>
      <ErrorModal error={error} onClear={clearError} />

    </Fragment>
  );
};

export default MyAccountAddress;
