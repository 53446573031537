import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import styles from './BackButton.modules.css'

const BackButton = () => {

    const linkStyle = {
        textDecoration: "none",
        color: 'white',
        fontSize: '1.6rem'
      };
    
    return (
        <div className={styles.backWrapper}>
        <Link  style={linkStyle} to={`/`}>
            <FontAwesomeIcon icon={faArrowLeft}/> { 'back to login'}
          </Link>
        </div>
    )
}

export default BackButton;