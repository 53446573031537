import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { MyCourseContext } from '../../context/myCourse-context';

import './TopLinks.css';

const TopLinks = ({ wallId, courses }) => {
  const auth = useContext(AuthContext);
  const { clearMyCourses } = useContext(MyCourseContext);
  const { REACT_APP_PUBLIC_URL } = process.env

  const [activeWallId, setActiveWallId] = useState();

  const logoutHandler = () => {
    clearMyCourses();
    auth.logout();
  };

  useEffect(() => {
    wallId && setActiveWallId(wallId);
  }, [wallId]);

  return (
    <React.Fragment>
      {!auth.isLoggedIn && (
        <ul className="top-links">
          <li>
            <a href={`${REACT_APP_PUBLIC_URL}`}>About Us </a>
          </li>
          <li>
            <a href={`${REACT_APP_PUBLIC_URL}`}>Program </a>
          </li>
          <li>
            <Link to="/enrol">Enrol </Link>
          </li>
          <li>
            <a href={`${REACT_APP_PUBLIC_URL}`}>Mission Statement </a>
          </li>
          <li>
          <a href={`${REACT_APP_PUBLIC_URL}`}>Contact Us</a>
          </li>
          <li>
            <Link to="/auth">Login </Link>
          </li>
        </ul>
      )}

      {auth.isLoggedIn && (
        <ul className="top-links">
          {/* <li>
            <Link to="/">Dashboard </Link>
          </li> */}

          <li>
            <Link to="/courses">Courses </Link>
          </li>
          <li>
            <Link to="/account-settings">My Account</Link>
          </li>
          <li>
            <button className="top-links top-links__logout" onClick={logoutHandler}>
              Logout
            </button>
          </li>
        </ul>
      )}
    </React.Fragment>
  );
};

export default TopLinks;
