import React from 'react';

import LessonItem from '../components/lessons/lessonItem';

import styles from './CourseIndex.module.css';

export const CourseIndex = ({ courseData }) => {
  return (
    <div className={styles.courseIndexWrapper}>
      <ul>
        <li>
          <h1>Course Curriculum</h1>
        </li>
        {courseData.lessons &&
          courseData.lessons.map(lesson => (
            <LessonItem key={lesson.id} lesson={lesson} />
          ))}
      </ul>
    </div>
  );
};
