import React, { useState, useEffect, useContext } from 'react';
import Ring from '../../../shared/components/UIElements/tubemap/Ring';
import CourseIndexBtn from './CourseIndexBtn';

import { MyCourseContext } from '../../../shared/context/myCourse-context';

import styles from './CourseIndexNav.module.css';

const CourseIndexNav = ({ myCourseData, courseData, selectedLession, update }) => {

  const [currentLession, setCurrentLession] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const { myCourses } = useContext(MyCourseContext);
  const [lessonList, setLessonList] = useState([]);

  const lessonSelectionHandler = lesson => {
    console.log(lesson);

    setCurrentLession(lesson.lessonNumber - 1);
    selectedLession(lesson.lessonNumber);
  };

  useEffect(() => {
    if (myCourseData) {
      myCourseData.startTime && setHasStarted(true);
    }
  }, [myCourseData]);

  useEffect(() => {
    console.log('MY COURSES UPDATE');

    if (myCourses) {
      const currentCourse = myCourses.find(
        course => course.courseId === courseData.id
      );

      if (currentCourse) {
        if (currentCourse.myLessons) {
          console.log(currentCourse.myLessons);
          let list = [];
          for (let x = 0; x < courseData.lessons.length; x++) {
            const mylessonFound = currentCourse.myLessons.find(
              myLesson => myLesson.lessonId === courseData.lessons[x].id
            );
            console.log(mylessonFound);
            if (mylessonFound) {
              list.push({
                id: courseData.lessons[x].id,
                title: courseData.lessons[x].title,
                lessonNumber: courseData.lessons[x].lessonNumber,
                complete: mylessonFound.complete,
              });
            } else {
              list.push({
                id: courseData.lessons[x].id,
                title: courseData.lessons[x].title,
                lessonNumber: courseData.lessons[x].lessonNumber,
                complete: false,
              });
            }
          }
          setLessonList(list);
        }
      }
    }
  }, [myCourses, courseData]);

  return (
    <div id={styles.navIndexWrapper}>
      <ul className={styles.indexList}>
        <CourseIndexBtn
          courseMyData={myCourseData}
          courseData={courseData}
          home={() => selectedLession(0)}
          update={update}
        />
        {lessonList.length > 0 &&
          hasStarted &&
          courseData.lessons &&
          lessonList.map((lesson, idx) => (
            <li
              key={lesson.id}
              className={`${styles.indexItem} ${
                idx === currentLession && styles.active
              }`}
              onClick={() => lessonSelectionHandler(lesson)}
            >
              <Ring
                completed={lesson.complete}
                last={courseData.lessons.length - 1 === idx ? true : false}
              />

              <p>{lesson.title}</p>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CourseIndexNav;
