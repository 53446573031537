import React from 'react'

import styles from './LessonText.module.css';

const LessonText = ({text}) => {
  return (
    <div className={styles.wrapper}> 
    <div className={styles.text}
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  ></div></div>
  )
}

export default LessonText