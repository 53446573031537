import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsPurchased } from '../../shared/hooks/isPurchased-hook';
import FeaturedImage from '../../shared/components/UIElements/FeaturedImage';
import styles from './CourseItem.module.css';

const CourseItem = ({ course }) => {
  
  console.log(course);

  const { isPurchased } = useIsPurchased(course.id);
  const [price, setPrice] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (course) {
      if (course.price) {
        console.log(course.price);

        setPrice('£' + (course.price / 100).toFixed(2));
      } else {
        setPrice('FREE');
      }
    }
  }, [course]);

  const courseSelectedHandler = () => {
    navigate(`/course/${course.id}`)
  }

  return (
    <li className={styles.courseItem} onClick={courseSelectedHandler}>
      <FeaturedImage src={course.featureImage}/>
      <h1>{course.title}</h1>
      {isPurchased ? 
        <div className={styles.purchased}>
        <p>Purchased</p>
      </div>
      : 
      <div className={styles.pricing}>
        <p>{price} </p>
      </div>
      }
     
     
    </li>
  );
};
export default CourseItem;
