import React from 'react'
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NoComments.module.css';
const NoComments = () => {
  return (
    <div className={styles.wrapper}>
      <FontAwesomeIcon icon={faCommentSlash} />
        <h1>No Comments yet</h1>
    </div>
  )
}

export default NoComments