import React from 'react'
import  CourseItem  from './CourseItem';

import styles from './CourseList.module.css';

const CourseList = ({courses}) => {
    return (
        <div className={styles.courseListWrapper}>
            <ul className={styles.courseList}>
                {courses && courses.map(course => (
                   course.active && <CourseItem key={course.id} course={course} />
                ))}
            </ul>
            
        </div>
    )
}

export default CourseList;