import React, { useEffect, useState, useContext} from 'react';

import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useUpdateMyCourses } from '../../../shared/hooks/updateMyCourses-hook';
import { MyCourseContext } from '../../../shared/context/myCourse-context';
import LoadingSpinner from '../../../shared/components/UIElements/Loading/LoadingSpinnerCenter';
import ErrorModal from '../../../shared/components/UIElements/Modal/ErrorModal';
import Button from '../../../shared/components/UIElements/Buttons/Button';

import styles from './CourseIndexBtn.module.css';

const CourseIndexBtn = ({ courseData, home, getStarted, update }) => {

    const { myCourses } = useContext(MyCourseContext);
    const { updateMyCourses } = useUpdateMyCourses();
    const [myCourse, setMyCourse] = useState()
    const [hasStarted, setHasStarted] = useState(false);
    const { token } = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { REACT_APP_API_URL } = process.env;

    useEffect(() => {
        console.log(courseData);
        console.log(myCourses);
       if (myCourses && courseData)  {
           const mc = myCourses.find(c => c.courseId === courseData.id);
           console.log(mc);
           setMyCourse(mc)
       }
    
    }, [myCourses, courseData]);

    const getStartedHandler = async () => {
      console.log(myCourse);
      console.log('start');
      try {
          const responseData = await sendRequest(
            `${REACT_APP_API_URL}/api/mycourse/start/${myCourse.id}`,
            'POST',
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
          );
          console.log(responseData);
          //responseData.success && getStarted();
          responseData.success && updateMyCourses();
          responseData.success && setHasStarted(true);
          responseData.success && update();
          
        } catch (err) {
          console.log(err);
        }
    }

    const loadCurriculumHandler = () => {
        home()
    }


    useEffect(() => {
       if (myCourse) {
        myCourse.startTime && setHasStarted(true);
       }
    }, [myCourse]);
    
    console.log(courseData);
  return <li className={styles.index}>
  <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
      
          <LoadingSpinner message="please wait..." />
       
      )}
  {
      hasStarted ? 
      <div className={styles.indexContent}>
      <Button title='Course Curriculum' onClick={loadCurriculumHandler}/>
     
      </div>
      :
      <div className={styles.indexContent}>
          <Button title={`Let's Get Started`} onClick={getStartedHandler}/>
          <p>Your remaining days will not be effected until you start the course.</p>
      </div>
  }

      
  </li>;
};

export default CourseIndexBtn;
