import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import styles from './LessonPDF.module.css';

const LessonPDF = ({ url, title }) => {
  const { REACT_APP_API_URL } = process.env;
  return (
    <div className={styles.downloadableContainer}>
      <FontAwesomeIcon icon={faFilePdf} />

      <a
        className={styles.downloadableContentLink}
        href={`${REACT_APP_API_URL}/${url}`}
        download={title}
        target="blank"
      >
        Download Workbook
      </a>
    </div>
  );
};

export default LessonPDF;
