import React, {useEffect, useState} from 'react';

import './Button.css';

const Button = props => {

    const [isDisabled, setIsDisabled] = useState()

    useEffect(() => {
        setIsDisabled(props.disabled);
    }, [props.disabled])


    return (
        <React.Fragment>

        {!isDisabled ? 
            <button 
            id={props.id} 
            type={props.type}
            onClick={props.onClick} 
            href={props.href}
            className={`button ${props.className}` }
            ref={props.ref} 
        >
           {props.children}
        </button>

         : 
    
        <button 
            id={props.id} 
            type={props.type}
            className={`button ${props.className} button-disabled` }
            ref={props.ref} 
        >
            <span>{props.title}</span>
        </button>

        }
        </React.Fragment>       
    );

}

export default Button;