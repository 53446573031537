import React, { Fragment, useState, useEffect }  from 'react';
import { Link, useParams } from "react-router-dom";
import { motion } from 'framer-motion';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';
import { useHttpClient } from '../../shared/hooks/http-hook';
import BackButton from './BackButton';

import styles from './ResetPassword.module.css';

const ResetPassword = () => {

    const pageVariants = {
        initial: {
          opacity: 0,
          x: 200,
        },
        in: {
          opacity: 1,
          x: 0, 
        },
        out: {
          opacity: 0,
          x: -200,
        },
    }

    const { REACT_APP_API_URL } = process.env;
  
    const token = useParams().token;
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [errors, setErrors] = useState();
    const [passError, setPassError] = useState(false);
    const [pass2Error, setPass2Error] = useState(false);
    const [message, setMessage] = useState();
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [success, setSuccess] = useState(false);
    

    useEffect(() => {
        const checkToken = async () => {
          try {
            const res = await sendRequest(
              `${REACT_APP_API_URL}/api/auth/validate-token`,
              'POST',
              JSON.stringify({
                token: token,
              }),
              {
                'Content-Type': 'application/json',
              }
            );
    
            if (res) {
              //console.log(res);
              setMessage(res.message);
            }
            setTokenIsValid(res.isValid);
          } catch (err) {
            //console.log(err);
            setTokenIsValid(false);
            setMessage(err.message);
          }
        };
        checkToken();
      }, []);
    

    const submitHandler = async event => {
        event.preventDefault();

        const password = event.target.password.value || null;
        const password2 = event.target.password2.value || null;
        resetErrors();

        if (password === null) {
          setErrors({ password: { type: 'required' } });
          setPassError(true)
        } 

        else if (password.length < 5) {
            setErrors({ password: { type: 'min char' } });
            setPassError(true);
        }  

        else if (password2 === null) {
          passError && setPassError(()=>false);
          setErrors({ password2: { type: 'required' } });
          setPass2Error(true);
        } 
        
        else if (password !== password2) {
            passError && setPassError(()=>false);
            setErrors({ password2: { type: 'no match' } });
            setPass2Error(true);
        }
        
        else {
    
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/user/update-password`,
            'PATCH',
            JSON.stringify({
                token: token,
                password: password,
            }),
            {
            'Content-Type': 'application/json',
            }
            );

            if (response) {
             //console.log(response);
             setSuccess(true);
            }
        }
        catch (err) {
          //console.log(err);
        }
      }
      }
    

      const resetErrors = () => {
        setErrors();
        setPassError(false);
        setPass2Error(false);
      }

    return (
        <>
            <ErrorModal error={error} onClear={clearError}/>
            <motion.div 
                  key={'reset password'}
                  className={styles.formWrapper}
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                > 
               
                  <h1 className={styles.formTitle}>{"reset password"}</h1>
                  {isLoading && <div className='center'><LoadingSpinner /></div>}
                 
                  {!isLoading && !tokenIsValid && 
                  <div>
                    <p className={styles.invalidMessage}>{message}.</p>
                    <Link className="forgot-link" to="/forgot">
                       { 'reset password'}
                    </Link>
                    </div>
                  }
                  {!isLoading && tokenIsValid && !success && 
                  <form className={styles.form} onSubmit={submitHandler}>
                  <div className={styles.inputWrapper}>
                  <label>{`new password`}:
                            <span className={passError ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.password &&
                                errors.password.type === 'required' &&
                                `password is required` }
                                {errors &&
                                errors.password &&
                                errors.password.type === 'min char' &&
                                `password must contain at least 5 characters`}
                            </span>
                        </label>
                        <Input id="password" name="password" type="password" placeholder={`required`} autoComplete="new-password" inputClassName={`${styles.input} ${passError ? styles.errorInput : styles.noError}`}/>
                  
                        <label>{`confirm password`}:
                        <span className={pass2Error ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.password2 &&
                                errors.password2.type === 'required' &&
                                `password confirmation is required`}
                                {errors &&
                                errors.password2 &&
                                errors.password2.type === 'no match' &&
                                `passwords do not match`}
                            </span></label>
                        <Input id="password2" name="password2" type="password" placeholder={`required`} autoComplete="new-password" inputClassName={`${styles.input} ${pass2Error ? styles.errorInput : styles.noError}`}/>
                    </div>
                    <div className={styles.actions}>
                      <Button  type="submit" className={`button-primary ${styles.enterBtn}`}  title={`update`}/>
                    </div>
                    <BackButton />
                    
                  </form>}

                  {!isLoading && success && 
                    <div className={styles.successWrapper}>
                      <h3 className={styles.successTitle}>{'success'}</h3>
                      <p className={styles.successText}>{'password success text'}</p>
                      <div className={styles.actions}>
                      <BackButton/>
                      </div>
                     
                    </div>
                    }
                  
            </motion.div>

        </>
    )
}

export default ResetPassword;