import React from 'react'

import styles from './FeaturedImage.module.css';

const FeaturedImage = ({ src }) => {

    const { REACT_APP_API_URL } = process.env;

    return (
       <img
        className={styles.image}
        src={`${REACT_APP_API_URL}/${src}`}
        alt="course feature"
      />
    )
}

export default FeaturedImage
