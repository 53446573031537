import React from 'react';
import PageTitle from '../../shared/components/UIElements/PageTitle';
import MyAccountAddress from '../components/MyAccountAddress';

import MyAccountSettings from '../components/MyAccountSettings';
import MyPurchaseHistory from '../components/MyPurchaseHistory';

import './UserAccount.css';

const UserAccount = () => {
  
  return (
    <div className="page-wrapper">
      <PageTitle title='Account Settings'/>
      <div className="content-wrapper">
        <div className="account-settings">         
          <MyAccountSettings />
          <MyAccountAddress/>
          <MyPurchaseHistory/>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
