import React, {useContext, useEffect } from 'react';
import {motion} from 'framer-motion';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { useCourses } from '../../shared/hooks/course-hook';

import Sidebar from '../../shared/components/UIElements/Sidebar';

import styles from './Courses.module.css';
import CourseList  from '../components/CourseList';
import PageTitle from '../../shared/components/UIElements/PageTitle';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const Courses = () => {

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { courses, createCourses } = useCourses();
  const { REACT_APP_API_URL } = process.env;

  // useEffect(() => {
  //   courses.length === 0 && getCourses();
  // }, []);
  

  const getCourses = async () => {

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/course/`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response);
      if(response) {
        if (response.courses) {
          createCourses(response);
        }
      }
        
    } catch (err) {
      console.log(err);
    }

  }

  return (
    <div className={styles.coursesWrapper}>
      <ErrorModal error={error} onClear={clearError} />
      <PageTitle title='Courses'/>
      <Sidebar>
       
        <div className={styles.sidebarContent}>
            <p>Some text about courses</p>
        </div>
      </Sidebar>
      <motion.div
        key="courses"
        className={styles.contentWrapper}
        initial={{ opacity: 0}}
        animate={{  opacity: 1, }}
        exit={{  opacity: 0}}
        transition={{ delay: .5 }}
      >

        {isLoading && <LoadingSpinner />}
        {!isLoading && courses.length > 0 && <CourseList courses={courses} />}
      
      </motion.div>
     
    </div>
  );
};

export default Courses;
