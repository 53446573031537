import React, { Fragment, useEffect, useState, useContext } from 'react';

import moment from 'moment';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

import './UpdateTeacher.css';

const UpdateTeacher = ({ userData }) => {
  const { REACT_APP_HFM_API_URL } = process.env;
  const { token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
 
  const [firstName, setFirstName] = useState('firstname');
  const [lastName, setLastName] = useState('lastname');
  const [email, setEmail] = useState('email');
  const [lastupdated, setLastupdated] = useState('01 01 2000');
  const [errors, setErrors] = useState();

  useEffect(() => {
    console.log(userData);
    if (userData) {
      userData.firstName && setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.email);
      setLastupdated(userData.updatedAt);
    }
  }, [userData]);

  const updateFirstName = e => {
    setFirstName(e.target.value);
  };

  const updateLastName = e => {
    setLastName(e.target.value);
  };

  const updateEmail = e => {
    setEmail(e.target.value);
  };

  const onSubmit = async event => {
    event.preventDefault();

    try {
      await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/teacher/update-teacher/${userId}`,
        'PATCH',
        JSON.stringify({
          firstName: event.target.firstName.value,
          lastName: event.target.lastName.value,
          email: event.target.email.value,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <Spinner
            className="spinner-primary"
            classNameText="spinner-text-primary"
            message="updating"
          />
        </div>
      )}
      {!isLoading && (
        <form onSubmit={onSubmit}>
        {errors && <label>
            <span className="inputError">
              {errors.firstName &&
                errors.firstName.type === 'required' &&
                'First name is required'}
              {errors.firstName &&
                errors.firstName.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>}
          <Input
            id="firstName"
            name="firstName"
            groupFront="First Name"
            value={firstName}
            onChange={updateFirstName}
            
          />

{errors && <label>
            <span className="inputError">
              {errors.lastName &&
                errors.lastName.type === 'required' &&
                'Last name is required'}
              {errors.lastName &&
                errors.lastName.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>}
          <Input
            id="lastName"
            name="lastName"
            groupFront="Last Name"
            value={lastName}
            onChange={updateLastName}
           
          />

{errors && <label>
            <span className="inputError">
              {errors.email &&
                errors.email.type === 'required' &&
                'Email is required'}
            </span>
          </label>}
          <Input
            id="email"
            name="email"
            groupFront="email"
            value={email}
            onChange={updateEmail}
           
          />
          <p className="personal-settings-container-p">
            Last updated: {moment(lastupdated).format('MMM Do YYYY')}
          </p>
          <Button
            type="submit"
            className="button-primary personal-update-btn"
            title="update"
          />
        </form>
      )}
    </Fragment>
  );
};

export default UpdateTeacher;
