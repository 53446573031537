import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';

import './MobileNav.css';

const MobileNav = ({wallId, courses}) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggle = () => {
    if (navOpen) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  };

  const { isLoggedIn, logout } = useContext(AuthContext);

  return (
    <div className="main-nav__wrapper">
      <div
        className={`bar-container ${navOpen ? 'change' : null}`}
        onClick={toggle}
      >
        <div className={`bar1`}></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>

      <div
        className={`nav-draw__wrapper ${!navOpen ? 'close' : null}`}
        onClick={toggle}
      >
        {!isLoggedIn && (
          <ul  className="mobile-nav">
            <li>
              <a className="mobile-nav-link" href="https://hellofutureminds.co.uk/about-us">About Us <span>Who are we?</span> </a>
            </li>
            <li>
              <a className="mobile-nav-link" href="https://hellofutureminds.co.uk/program">Program <span>What we offer</span></a>
            </li>
            <li>
              <NavLink className="mobile-nav-link" to="/enrol"> Enrol<span>It's FREE to Enrol</span> </NavLink>
            </li>
            <li>
              <a className="mobile-nav-link" href="/mission-statement">Mission Statement<span>Our goals</span></a>
            </li>
            <li>
              <NavLink className="mobile-nav-link" to="/contact">Contact <span>Any questions?</span></NavLink>
            </li>
            <li>
              <NavLink className="mobile-nav-link" to="/auth">Login <span>Welcome back!</span></NavLink>
            </li>
          </ul>
        )}

        {isLoggedIn && (
          <ul className="mobile-nav">
            <li>
              <NavLink className="mobile-nav-link" to="/">Dashboard<span>Your personal space!</span></NavLink>
            </li>
            {wallId &&
            <li>
                <NavLink className="mobile-nav-link" to={`/classroom/${wallId}`}>Classroom <span>Get your progress on!</span></NavLink>
            </li>
          }
            <li>
              <NavLink className="mobile-nav-link" to="/social-wall">Social Wall<span>Our community</span></NavLink>
            </li>
            <li>
              <NavLink className="mobile-nav-link" to="/account-settings">My Account<span>Your information</span></NavLink>
            </li>
            {courses && 
              <li><a href="#">Courses</a>
                <ul className={"mobile-nav-submenu"}>
                  {courses.map(course => (
                    <li key={course.id}>
                      <NavLink className="mobile-subnav-link" to={`/course/${course.id}`}>{course.title}</NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            }
            <li>
              <button className="mobile-nav-link mobile-nav-link-button"  onClick={logout} > Logout <span>See you again soon!</span></button>
            </li>
          </ul>
        )}

       
      </div>
    </div>
  );
};

export default MobileNav;
