import { faAlignLeft, faFilePdf, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './lessonItem.module.css';

const LessonItem = ({ lesson }) => {
  console.log(lesson);
  return (
    <li className={styles.lesson}>
    <div className={styles.iconWrapper}>
    {lesson.text && <FontAwesomeIcon className={styles.icon} icon={faAlignLeft}/>}
    {lesson.downloadableUrl && <FontAwesomeIcon className={styles.icon}  icon={faFilePdf}/>}
    {lesson.videoUrl && <FontAwesomeIcon className={styles.icon}  icon={faPlayCircle}/>}
    </div>
   
      <h1 className={styles.lessonNumber}>Lesson: {lesson.lessonNumber}</h1>
      <h1 className={styles.lessonTitle}>{lesson.title}</h1>

    </li>
  );
};

export default LessonItem;
