import React, { useState, useEffect, useContext } from 'react';

import ErrorModal from '../UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';

import styles from './Discussion.module.css';
import Post from './Post';
import PostBoard from './PostBoard';
import LoadingSpinnerCenter from '../UIElements/Loading/LoadingSpinnerDark';

const Discussion = ({ lessonId }) => {

const [posts, setPosts] = useState([])
const [update, setUpdate] = useState(false);
const { REACT_APP_API_URL } = process.env;
const auth = useContext(AuthContext);
const { isLoading, error, sendRequest, clearError } = useHttpClient();

useEffect(() => {
  console.log('UPDATE POSTLIST');

  const fetchPosts = async () => {
    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/discussion/get/${lessonId}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (response) {
        if (response.isSuccess) {   
          console.log(response.posts);
          setPosts(response.posts);
        }
      }
    } catch (err) {
      //console.log(err);
    }
  }

  fetchPosts();
}, [update, lessonId])



  return (
    <div className={styles.wrapper}>
    <ErrorModal error={error} onClear={clearError} />
      <div className={styles.header}>
        <h1>Discussion</h1>
      </div>
      <div className={styles.body}>
        <Post lessonId={lessonId} update={()=>setUpdate(state=>!state)}/>
        <PostBoard posts={posts}/>
      </div>
      {isLoading && <LoadingSpinnerCenter message='Loading Comments' />}
    </div>
  );
};

export default Discussion;
