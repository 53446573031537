import React from 'react';
import { AnimatePresence, motion} from 'framer-motion';

import styles from './Sidebar.module.css';

const Sidebar = ({ children }) => {


  return (
<AnimatePresence>
      <motion.div
        key="drawer"
        className={styles.sidebar}
        initial={{ opacity: 0, left: -500 }}
        animate={{  left: 0, opacity: 1, }}
        exit={{  opacity: 0, left: -500 }}
      >
       {children}
      </motion.div>
      </AnimatePresence>
   
  );
};

export default Sidebar;
