import React, { useState, useContext } from 'react';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingDots from '../UIElements/Loading/LoadingDots';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';

import Input from '../FormElements/Input';
import styles from './Post.module.css';
import { Fragment } from 'react';

const Post = ({ lessonId, update }) => {
  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [postBody, setPostBody] = useState('');

  const updateQuestionHandler = event => {
    setPostBody(event.target.value);
  };

  const postMessege = async event => {
    event.preventDefault();

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/discussion/${lessonId}`,
        'POST',
        JSON.stringify({
          postBody: event.target.post.value,
          teacherId: auth.userId,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (response) {
        if (response.isSuccess) {
          update();
          setPostBody('');
        }
      }
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form
        className={styles.postForm}
        autoComplete="off"
        onSubmit={postMessege}
      >
        <Input
          inputClassName={styles.postInput}
          id="post"
          value={postBody}
          onChange={updateQuestionHandler}
          name="post"
          type="text"
          autoComplete="off"
          placeholder="Post a Comment..."
        />
        {!isLoading && postBody && (
          <button className={styles.askButton}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        )}
        {isLoading && <LoadingDots className={styles.loadingDots} />}
      </form>
    </Fragment>
  );
};
export default Post;
