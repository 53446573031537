import React from 'react'
import moment from 'moment';
import styles from './PostBody.module.css';

const PostBody = ({post}) => {

  return (
    <div className={styles.postBody} style={{}}>
    <h1 className={styles.senderName}>{post.teacher.firstName} {post.teacher.lastName}  </h1>
    <p className={styles.bodyText}>{post.postBody}</p>
    <p className={styles.postedTime}>Posted: <span>{moment(post.createdAt, "YYYY-MM-DDTHH:mm:ss").fromNow()}</span></p>
    </div>
  )
}

export default PostBody