import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { useHttpClient } from '../../shared/hooks/http-hook';

import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';

import styles from './Auth.module.css';
import LoadingEvent from '../components/LoadingEvent';
import VersionNumber from '../../shared/components/versionNumber/VersionNumber';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const Auth = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: -100,
    },
    in: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5,
      },
    },
    out: {
      opacity: 0,
      x: -100,
    },
  };

  const backgroundData = [
    {
      img: 'classroom.jpeg',
      position: 'center right',
    }
  ];

  const { REACT_APP_API_URL } = process.env;

  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const random = Math.floor(Math.random() * backgroundData.length);
  const [background, setBackground] = useState({
    img: 'classroom.jpeg',
    position: 'center center',
  });



  useEffect(() => {
    setBackground(backgroundData[random]);

  }, []);

  return (
    <>
    {/* // <AnimatePresence exitBeforeEnter> */}
      <div id={styles.wrapper}>
      <ErrorModal error={error} onClear={clearError} />
      <div className={styles.authLangWrapper}>
   
      </div>
        <div id={styles.leftContiner}>
        <AnimatePresence exitBeforeEnter>
          <motion.img
            key={'Main Branding'}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.branding}
            src="/logo.png"
            alt="Logo"
          />
          </AnimatePresence>
        </div>
        <div
          id={styles.rightContainer}
          style={{
            backgroundImage: `url("/assets/${background.img}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: background.position,
            backgroundSize: 'cover',
            
          }}
        >
        <div className={styles.overlay}></div>
         <AnimatePresence exitBeforeEnter>
         
            <Routes>
              <Route path="/" exact element={ <LoginForm />}/>
              <Route path="/enrol" exact  element={<RegisterForm />}/>
              <Route path="/forgot" exact  element={ <ForgotPassword  />}/>
              <Route path="/reset/:token" exact  element={ <ResetPassword />} />
              <Route path="*"  element={<LoginForm />} />
            </Routes>
          
          </AnimatePresence>
          {isLoading && <LoadingEvent />}
      
        </div>
      </div>
      <VersionNumber/>
    {/* // </AnimatePresence> */}
    </>
  );
};

export default Auth;
