import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import Gauge from '../../shared/components/UIElements/gauge/gauge';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import { useMyCurrentCourse } from '../../shared/hooks/myCurrentCourse-hook';
import styles from './GaugePanel.module.css';
import LoadingSpinnerCenter from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';

const GaugePanel = () => {

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const { myCourse } = useMyCurrentCourse();
  const [maxDays, setMaxDays] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isExpired, setIsExpired] = useState(false);

  const countDays = () => {
    const endDate = moment(myCourse.startTime).add(
      myCourse.course.purchaseDurationDays,
      'days'
    );
    var a = moment(endDate);
    var b = moment();
    const days = a.diff(b, 'days') + 1;
    setDaysLeft(days > 0 ? days : 0);
    days < 0 && setIsExpired(true);
  };

  useEffect(() => {
    if (myCourse) {
      console.log(myCourse);
      setMaxDays(myCourse.course.purchaseDurationDays);
      if (myCourse.startTime) {
        countDays();
      } else {
        const daysRemaining = myCourse.course.purchaseDurationDays; 
        console.log(daysRemaining);
        setDaysLeft(daysRemaining);
        
      }
      const completeLessons = [];
      
      if ( myCourse.myLessons) {
        
        for (let x = 0; x < myCourse.myLessons.length; x++) {
          myCourse.myLessons[x].complete &&
            completeLessons.push(myCourse.myLessons[x]);
        }
      }

      if (myCourse.course) {
        if (myCourse.course.lessons) {
          const lessonsCount = myCourse.course.lessons.length;
          const completeLessonCount = completeLessons.length;

          setProgress(Math.floor((completeLessonCount / lessonsCount) * 100));
        }
      }
    }
  }, [myCourse]);


    const deleteMyCourse = async () => {
console.log(myCourse.id);
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/mycourse/${myCourse.id}`,
          'DELETE',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
  
        console.log(response);
        if(response) {
          if (response.isSuccess) {
             navigate('/courses');
            ;
          }
        }
          
      } catch (err) {
        console.log(err);
      }
      
    }
   
    useEffect(()=> {

      isExpired && deleteMyCourse()

    },[isExpired, myCourse])

  

  return (
    <div className={styles.progressContainer}>
   {isLoading && <LoadingSpinnerCenter />}
      {myCourse && (
        <Gauge
          className={styles.gaugeWrapper}
          label="Progress"
          units="%"
          value={progress}
        />
      )}
      {myCourse && (
        <Gauge
          className={styles.gaugeWrapper}
          label="Remaining"
          units="Days"
          value={daysLeft}
          max={maxDays}
          min={0}
        />
      )}
    </div>
  );
};

export default GaugePanel;
