import { useState, useEffect, useContext } from 'react';
import { MyCourseContext} from '../context/myCourse-context.js';

export const useIsPurchased = (courseId) => {

  const [isPurchased, setIsPurchased] = useState(false);
  const { myCourses } = useContext(MyCourseContext);

  useEffect(() => {
    console.log(courseId);
    if (myCourses) {
      const myCourse = myCourses.find(mc => mc.courseId === courseId)
      console.log(myCourse);
      myCourse && !myCourse.isExpired && setIsPurchased(true);
    }
  }, [courseId, myCourses]);

  console.log(isPurchased);
  return { isPurchased };
};
