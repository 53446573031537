import { useState, useCallback, useEffect, useContext } from 'react';

import { AuthContext } from '../context/auth-context';
import { MyCourseContext } from '../context/myCourse-context';
import { useHttpClient } from './http-hook';

export const useUpdateMyCourses = () => {

  const [myCourses, setMyCourses] = useState(null);
  const { token, userId } = useContext(AuthContext);
  const {createMyCourses} = useContext(MyCourseContext);

  const { REACT_APP_API_URL } = process.env;
  const { sendRequest } = useHttpClient();

  const getMyCourses = async () => {

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/mycourse/get/${userId}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }
      );

      console.log(response);
      if(response) {
        if (response) {
          setMyCourses(response);
          createMyCourses(response);
        }
      }
        
    } catch (err) {
      console.log(err);
    }

  }

  
  const updateMyCourses = useCallback((myCourses) => {
    createMyCourses(myCourses);
    console.log('setting my courses Context');
    },
    []
  );

  
  
  

  return { myCourses, updateMyCourses, getMyCourses };
};
